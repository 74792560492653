import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import ResetPassword from './ResetPassword';
import Layout from '../../layouts/index';

const ResetPasswordContainer = ({ location }) => {
  if (typeof location === 'undefined') return null;
  return (
    <Layout>
      <Router
        location={location}
        key={location.key}
      >
        <ResetPassword
          path="/nouveau-mot-de-passe/:token"
        />
      </Router>
    </Layout>
  );
};

ResetPasswordContainer.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
  }),
};

ResetPasswordContainer.defaultProps = {
  location: undefined,
};

export default ResetPasswordContainer;
